<template>
  <div class="chatbot">
    <div v-if="loadingConversation" class="chatbot__loading-conversation">
      <Loader
        class="small-loader small-text mt-5 justify-start"
        text="Loading conversation..."
      ></Loader>
    </div>
    <div
      @click="newChat"
      class="
        chatbot__tools-btn chatbot__tools-btn--new-chat
        flex
        items-center
        gap-5
      "
      :class="{ 'chatbot__tools-btn--disabled': animatedMessages.length < 2 }"
    >
      <icon-base
        :width="14"
        :height="14"
        :viewBox1="24"
        :viewBox2="24"
        iconName="plus"
        iconStroke="transparent"
      >
        <icon-plus></icon-plus>
      </icon-base>
      New Chat
    </div>
    <div
      @click="getChatHistory"
      class="chatbot__tools-btn flex items-center gap-5"
      :class="{ 'is-active': showHistory }"
    >
      <icon-base
        :width="14"
        :height="14"
        :viewBox1="493"
        :viewBox2="511.77"
        iconName="history"
        iconColor="#fff"
        iconStroke="transparent"
      >
        <icon-chat-history></icon-chat-history>
      </icon-base>
      Chat History
    </div>
    <div
      class="chatbot__history-wrapp cursor-pointer"
      @click="showHistory = false"
      v-if="showHistory"
    >
      <div class="chatbot__history cursor-default" @click.stop>
        <div class="flex justify-between items-center mb-15">
          <h2 class="mb-0">Chat History</h2>
          <a-icon type="close" @click="showHistory = false" />
        </div>

        <Loader
          v-if="loadingHistory"
          class="small-loader small-text mt-5 justify-start"
          text="Loading chat history..."
        ></Loader>
        <ul v-else-if="chatHistory.length > 0">
          <li
            v-for="item in chatHistory"
            :key="item.conversationId"
            @click="getConversation(item.conversationId)"
          >
            {{ item.content }}
          </li>
        </ul>
        <div v-else class="font-size-13">No chat history.</div>
      </div>
    </div>
    <div class="chatbot__container">
      <div class="chatbot__messages-container">
        <div
          class="chatbot__messages"
          v-for="(message, index) in animatedMessages"
          :key="index + conversationId"
        >
          <div class="chatbot__message" :class="message.role">
            <div v-if="message.role === 'user'" class="chatbot__sender">Me</div>
            <div class="chatbot__text">
              <vue-marked :key="Math.random()">
                {{ message.content }}</vue-marked
              >
            </div>
          </div>
        </div>

        <div class="chatbot__loading" v-if="loadingAssistantAnswer">
          <div class="chatbot__loader"></div>
          <div class="chatbot__loader"></div>
          <div class="chatbot__loader"></div>
        </div>
      </div>

      <div class="chatbot__input-container">
        <input
          v-model="userInput"
          @keydown.enter.prevent="sendQuestions"
          placeholder="Enter your question..."
          class="chatbot__input"
        />
        <a-button
          type="primary"
          @click="sendQuestions"
          :disabled="btnDisabled || userInput === '' || loadingAssistantAnswer"
          class="chatbot__send-button"
        >
          <icon-base
            :width="32"
            :height="32"
            :viewBox1="122.56"
            :viewBox2="122.88"
            iconName="send"
            iconColor="#fff"
            iconStroke="transparent"
          >
            <icon-send></icon-send>
          </icon-base>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconSend from "@/components/icons/IconSend.vue";
import IconChatHistory from "@/components/icons/IconChatHistory.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import { v4 as uuidv4 } from "uuid";
import VueMarked from "@hrwg/vue-marked";
import Loader from "@/components/general/Loader.vue";

export default {
  name: "ChatBot",
  components: {
    IconBase,
    IconSend,
    IconChatHistory,
    VueMarked,
    Loader,
    IconPlus,
  },
  data() {
    return {
      user: null,
      userInput: "",
      messages: [],
      animatedMessages: [],
      btnDisabled: false,
      loadingAssistantAnswer: false,
      lastMessageCount: 0,
      conversationId: null,
      showHistory: false,
      loadingHistory: false,
      chatHistory: [],
      initMessage: "Welcome to the CharlesBot! How can I help you today?",
      loadingConversation: false,
    };
  },

  methods: {
    sendQuestions() {
      if (
        this.userInput.trim() === "" ||
        this.btnDisabled ||
        this.loadingAssistantAnswer
      ) {
        return;
      }

      this.loadingAssistantAnswer = true;
      this.messages.push({
        content: this.userInput,
        role: "user",
      });
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);

      const data = {
        wsName: "chatbotquery",
        data: {
          query: this.userInput,
          userId: this.user ? this.user.EmailId : null,
          conversationId: this.conversationId,
        },
      };

      this.userInput = "";

      const chatBot = new Promise((resolve, reject) => {
        wsUtils.ChatBot(data, (error, data) => {
          if (error) {
            console.error("API Error:", error);
            reject(error);
          } else {
            resolve(data);
          }
        });
      });

      chatBot
        .then((data) => {
          if (data) {
            this.messages.push({
              content:
                data === "<I cannot find any information>"
                  ? "I cannot find any information."
                  : data,
              role: "assistant",
            });
          }
          this.loadingAssistantAnswer = false;
        })
        .catch(() => {
          this.$notification["error"]({
            message: "Error!",
            description: "Something went wrong, please try again.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.loadingAssistantAnswer = false;
        });
    },
    getChatHistory() {
      if (this.showHistory) {
        this.showHistory = false;
        return;
      }
      this.showHistory = true;
      this.loadingHistory = true;

      const getChatHistory = new Promise((resolve, reject) => {
        wsUtils.GetChatHistory(
          {
            userId: this.user ? this.user.EmailId : null,
          },
          (error, data) => {
            if (error) {
              reject(error);
            } else {
              resolve(data);
            }
          }
        );
      });

      getChatHistory
        .then((data) => {
          const uniqueChatHistory = data.reduce((acc, current) => {
            if (
              !acc.some(
                (item) => item.conversationId === current.conversationId
              )
            ) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.chatHistory = uniqueChatHistory;
        })
        .catch((error) => {
          this.$notification["error"]({
            message: "Error!",
            description: `Something went wrong: ${error.message}`,
            placement: "bottomLeft",
            duration: 5,
          });
          this.loadingAssistantAnswer = false;
        })
        .finally(() => {
          this.loadingHistory = false;
        });
    },

    getConversation(conversationId) {
      this.loadingConversation = true;
      this.showHistory = false;
      this.userInput = "";
      const chatContainer = document.querySelector(
        ".chatbot__messages-container"
      );
      chatContainer.scrollTop = 0;
      const getConversation = new Promise((resolve, reject) => {
        wsUtils.GetConversation(
          {
            userId: this.user ? this.user.EmailId : null,
            conversationId: conversationId,
          },
          (error, data) => {
            if (error) {
              reject(error);
            } else {
              resolve(data);
            }
          }
        );
      });

      getConversation
        .then((data) => {
          this.conversationId = conversationId;
          this.lastMessageCount = 0;
          const modifiedData = data.map((message) => {
            if (message.content === "<I cannot find any information>") {
              message.content = "I cannot find any information.";
            }
            return message;
          });
          this.animatedMessages = modifiedData;
          this.animatedMessages.unshift({
            content: this.initMessage,
            role: "assistant",
          });
        })
        .catch(() => {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Getting conversation failed, please try again or contact support.",
            placement: "bottomLeft",
            duration: 5,
          });
        })
        .finally(() => {
          this.loadingConversation = false;
        });
    },

    addAnimatedMessage(message) {
      this.animatedMessages.push({
        content: message.content,
        role: message.role,
        displayedText: "",
      });

      this.typeText(this.animatedMessages.length - 1);
    },

    typeText(index) {
      const currentMessage = this.animatedMessages[index];
      currentMessage.displayedText = currentMessage.content;
      this.scrollToBottom();
    },

    scrollToBottom() {
      const chatContainer = document.querySelector(
        ".chatbot__messages-container"
      );
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },
    newChat() {
      this.showHistory = false;
      this.animatedMessages = [];
      this.animatedMessages.push({
        content: this.initMessage,
        role: "assistant",
      });
      this.conversationId = uuidv4();
    },
  },

  watch: {
    messages(newMessages) {
      if (newMessages.length > this.lastMessageCount) {
        const newMessageIndex = newMessages.length - 1;
        this.addAnimatedMessage(newMessages[newMessageIndex]);
        this.scrollToBottom();
        this.lastMessageCount = newMessages.length;
      }
    },
  },

  mounted() {
    this.user = jsUtils.getUserInfo();
    this.conversationId = uuidv4();
    this.messages.push({
      content: this.initMessage,
      role: "assistant",
    });
  },
};
</script>

<style lang="scss" scoped>
.chatbot {
  position: relative;
  margin-top: 10px;
  cursor: default;
  &__loading-conversation {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    border-radius: 12px;
    background-color: #f5f3f1;
    background: rgba(245, 243, 241, 0.9);
    z-index: 99;
  }

  &__container {
    z-index: 50;
    display: flex;
    flex-direction: column;
    min-height: 410px;
    position: relative;
  }
  &__tools-btn {
    position: absolute;
    top: -23px;
    right: 0;
    z-index: 100;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    &:hover,
    &.is-active {
      color: var(--blue);
      svg {
        path {
          fill: var(--blue);
        }
      }
    }
    &--new-chat {
      right: 106px;
    }
    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__history-wrapp {
    position: absolute;
    inset: 0;
    z-index: 99;
    background: rgba(245, 243, 241, 0.8);
    border-radius: 12px;
    border: 1px solid #f3edeb;
    overflow: hidden;
  }
  &__history {
    width: 240px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: #fff;
    padding: 20px 0 0 25px;
    box-shadow: 10px 0px 17px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    .anticon-close {
      margin-right: 20px;
    }
    ul {
      margin: 0;
      padding: 0 25px 20px 0;
      overflow-y: hidden;
      &:hover {
        overflow-y: auto;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.2);
      }

      li {
        font-size: 14px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: var(--blue);
        }
      }
    }
  }
  &__spinner {
    border: 2px solid gray;
    border-top: 2px solid #999;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__messages-container {
    background: rgba(245, 243, 241, 0.5);
    padding: 25px 25px 150px;
    border-radius: 12px;
    position: relative;
    flex-grow: 1;
    max-height: 450px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
  }

  &__message {
    padding: 8px 13px;
    border-radius: 12px;
    margin-bottom: 15px;
    max-width: 90%;
    &.user {
      background-color: var(--blue);
      color: white;
      align-self: flex-end;
      box-shadow: -2px 2px 7px -3px rgba(79, 64, 112, 1);
    }
    &.assistant {
      background-color: white;
      color: black;
      align-self: flex-start;
      box-shadow: 13px 18px 9px -20px rgba(0, 0, 0, 0.1);
    }
  }

  &__sender {
    font-size: 10px;
    color: #404040;
    position: absolute;
    top: -22px;
    right: 0;
    display: flex;
    gap: 1.5px;
    white-space: nowrap;
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    > div {
      ::v-deep *:last-child {
        margin-bottom: 0;
      }
      ::v-deep {
        li {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  &__loader {
    width: 12px;
    height: 12px;
    background-color: #e6d9c4;
    border-radius: 50%;
    animation: bounce 0.5s infinite alternate;
    margin-right: 8px;
    &:nth-child(2) {
      animation: bounce1 0.5s infinite alternate;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes bounce1 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  &__input-container {
    display: flex;
    gap: 12px;
    padding: 45px 24px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 12px 12px;
    background: linear-gradient(to bottom, transparent 0%, #faf9f8 18%);
  }

  &__input {
    flex: 1;
    height: 60px;
    padding: 0 24px;
    font-size: 14px;
    border-radius: 50px;
    border: none;
    outline: none;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
  }

  &__send-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 11px 18px -4px rgba(129, 186, 76, 0.8);
    &:hover {
      svg {
        transform: translateX(2px) translateY(-2px);
        transition: transform 0.3s;
      }
    }
    svg {
      transform: translateX(-2px) translateY(2px);
      transition: transform 0.3s ease-in-out;
    }
  }

  &__send-icon {
    color: white;
  }
}
</style>
